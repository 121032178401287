const colors = {
  1: 'green',
  2: 'yellow',
  '-2': 'red',
  3: '#000',
}

export default status => (
  colors[status]
)
