import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'

import { ListUnits } from 'pages'

const Action = ({ id }) => (
  <Link to={`/product-purchase/${id}`}>
    <Button icon="arrow-right" />
  </Link>
)

class ProductPurchase extends Component {
  render() {
    return <ListUnits action={id => <Action id={id} />} />
  }
}

export default ProductPurchase
