import React, { Component } from 'react'
import { List, Input } from 'antd'

class RenderList extends Component {
  state = { value: '' }

  render() {
    const {
      id,
      vixenId,
      name,
      info,
      handleInputChange,
    } = this.props
    const { value } = this.state

    return (
      <List.Item>
        <List.Item.Meta title={`${id} - ${name}`} />
        <div className="list-item">
          { info && <span className={info.type}>{ info.message }</span> }
          <Input
            size="small"
            style={{ width: 100, textAlign: 'right' }}
            defaultValue={vixenId}
            value={value || vixenId}
            onChange={e => this.setState({ value: e.target.value })}
            onBlur={e => handleInputChange(this.props, e.target.value)}
          />
        </div>
      </List.Item>
    )
  }
}

export default RenderList
