import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { Router, Switch, Route } from 'react-router-dom'
import { LocaleProvider } from 'antd'
import ptBR from 'antd/lib/locale-provider/pt_BR'

import oauth from 'services/oauth'
import store from 'state'
import history from 'shared/history'
import Login from 'pages/auth/Login'
import BaseLayout from 'pages/BaseLayout'

class App extends Component {
  async componentWillMount() {
    const isLoggedIn = await oauth.isLoggedIn()
    if (!isLoggedIn) {
      history.push('/login')
    }
  }

  render() {
    return (
      <LocaleProvider locale={ptBR}>
        <Provider store={store}>
          <Router history={history}>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route path="/" component={BaseLayout} />
            </Switch>
          </Router>
        </Provider>
      </LocaleProvider>
    )
  }
}

export default App
