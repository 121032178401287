import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import { notification } from 'antd'
import { t } from 'ab18n'

import api from 'services/api'
import { actions, types } from './actions'

function* fetchConfigSaga(action) {
  const { unitId } = action.meta

  yield put(actions.setLoading(true))
  yield put(actions.setConfig(null))
  try {
    const response = yield call(api.get, `admin/units/${unitId}/configuration`)
    yield put(actions.setConfig(response))
  } catch (error) {
    if (error.response.status !== 404) {
      notification.error({
        message: t('config.notification.error.fetch'),
        duration: null,
      })
      yield put(actions.setConfig(null))
    } else {
      yield put(actions.setConfig({}))
    }
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* saveConfigSaga(action) {
  const { payload, meta: { unitId, update } } = action

  yield put(actions.setLoading(true))
  try {
    yield call(
      update ? api.put : api.post,
      `admin/units/${unitId}/configuration`,
      payload,
    )
    notification.success({
      message: t('config.notification.success'),
      duration: null,
    })
    yield put(actions.setConfig(payload))
  } catch (error) {
    notification.error({
      message: t('config.notification.error.save'),
      duration: null,
    })
    yield put(actions.setConfig(null))
  } finally {
    yield put(actions.setLoading(false))
  }
}

export default function* () {
  yield all([
    takeLatest(types.FETCH_CONFIG, fetchConfigSaga),
    takeLatest(types.SAVE_CONFIG, saveConfigSaga),
  ])
}
