import React, { Component } from 'react'
import { t } from 'ab18n'
import {
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
} from 'antd'

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

class ConfigForm extends Component {
  componentDidMount() {
    const { form } = this.props
    form.validateFields()
  }

  handleSubmit = (e) => {
    const { form, submit } = this.props

    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        submit(values)
      }
    })
  }

  render() {
    const {
      form: {
        getFieldDecorator,
        getFieldsError,
        getFieldError,
        isFieldTouched,
      },
      config,
    } = this.props

    const maximumProductDiscountError = isFieldTouched('maximumProductDiscount')
      && getFieldError('maximumProductDiscount')
    const maximumCourtesyServicesError = isFieldTouched('maximumCourtesyServices')
      && getFieldError('maximumCourtesyServices')

    return (
      <Form
        layout="vertical"
        className="config-form"
        onSubmit={this.handleSubmit}
      >
        <Row type="flex" gutter={16} align="bottom">
          <Col span={12}>
            <Form.Item
              validateStatus={maximumProductDiscountError ? 'error' : ''}
              help={maximumProductDiscountError || ''}
              className="form-field"
              label={t('config.form.maximumProductDiscount')}
            >
              {
                getFieldDecorator(
                  'maximumProductDiscount',
                  {
                    rules: [
                      {
                        required: true,
                        message: t('config.form.validate'),
                      },
                    ],
                    initialValue: config.maximumProductDiscount,
                  },
                )(
                  <Input
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/\D/g, '')
                    }}
                  />,
                )
              }
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              validateStatus={maximumCourtesyServicesError ? 'error' : ''}
              help={maximumCourtesyServicesError || ''}
              className="form-field"
              label={t('config.form.maximumCourtesyServices')}
            >
              {
                getFieldDecorator(
                  'maximumCourtesyServices',
                  {
                    rules: [
                      {
                        required: true,
                        message: t('config.form.validate'),
                      },
                    ],
                    initialValue: config.maximumCourtesyServices,
                  },
                )(
                  <Input
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/\D/g, '')
                    }}
                  />,
                )
              }
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          className="form-field"
        >
          {getFieldDecorator('requireSerialNumber', {
            valuePropName: 'checked',
            initialValue: config.requireSerialNumber,
          })(
            <Checkbox>
              { t('config.form.requireSerialNumber') }
            </Checkbox>,
          )}
        </Form.Item>
        <Form.Item
          className="form-field"
        >
          {getFieldDecorator('sendItemsRevenueService', {
            valuePropName: 'checked',
            initialValue: config.sendItemsRevenueService,
          })(
            <Checkbox>
              { t('config.form.sendItemsRevenueService') }
            </Checkbox>,
          )}
        </Form.Item>
        <Form.Item
          className="form-field"
        >
          {getFieldDecorator('enableTimeline', {
            valuePropName: 'checked',
            initialValue: config.enableTimeline,
          })(
            <Checkbox>
              { t('config.form.enableTimeline') }
            </Checkbox>,
          )}
        </Form.Item>
        <Form.Item
          label={t('config.form.identifierTypes')}
          className="form-field"
        >
          {getFieldDecorator('identifierTypes', {
            initialValue: (config.identifierTypes || [])
              .map(item => String(item)),
          })(
            <Checkbox.Group style={{ width: '100%' }}>
              <Row>
                <Col span={2}>
                  <Checkbox value="0">
                    { t(`config.identifierTypes.${0}`) }
                  </Checkbox>
                </Col>
                <Col span={2}>
                  <Checkbox value="1">
                    { t(`config.identifierTypes.${1}`) }
                  </Checkbox>
                </Col>
                <Col span={2}>
                  <Checkbox value="2">
                    { t(`config.identifierTypes.${2}`) }
                  </Checkbox>
                </Col>
                <Col span={2}>
                  <Checkbox value="3">
                    { t(`config.identifierTypes.${3}`) }
                  </Checkbox>
                </Col>
                <Col span={2}>
                  <Checkbox value="4">
                    { t(`config.identifierTypes.${4}`) }
                  </Checkbox>
                </Col>
                <Col span={2}>
                  <Checkbox value="5">
                    { t(`config.identifierTypes.${5}`) }
                  </Checkbox>
                </Col>
                <Col span={2}>
                  <Checkbox value="6">
                    { t(`config.identifierTypes.${6}`) }
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>,
          )}
        </Form.Item>
        <Form.Item
          className="form-field form-submit"
        >
          <Button
            type="primary"
            htmlType="submit"
            disabled={hasErrors(getFieldsError())}
          >
            { t('config.form.submit') }
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

export default Form.create({ name: 'config_form' })(ConfigForm)
