import { typeActions } from 'state/util'

const { actions, types } = typeActions('coupons', {
  fetchProducts: unitId => ({ meta: { unitId } }),
  fetchCoupons: unitId => ({ meta: { unitId } }),
  changeTab: tab => ({ meta: { tab } }),
  setTab: tab => ({ meta: { tab } }),
  generateCoupons: (products, unitId) => ({
    payload: products,
    meta: { unitId: unitId.id },
  }),
  copyCoupons: coupons => ({ payload: coupons }),
  setAll: (data = null, error = null) => ({
    payload: data,
    error,
  }),
  removeCoupon: (data, unitId) => ({
    payload: data,
    meta: { unitId },
  }),
  prepareRemove: data => ({ payload: data }),
  setLoading: value => ({ meta: { loading: value } }),
})

export {
  actions,
  types,
}
