import { typeActions } from 'state/util'

const { actions, types } = typeActions('config', {
  fetchConfig: unitId => ({ meta: { unitId } }),
  saveConfig: (data, meta) => ({
    payload: data,
    meta,
  }),
  setConfig: (data = null, error = null) => ({
    payload: data,
    error,
  }),
  setLoading: value => ({ meta: { loading: value } }),
})

export {
  actions,
  types,
}
