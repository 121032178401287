import 'moment/locale/pt-br'

const dictionary = {
  general: {
    logo: 'Admin',
  },
  auth: {
    login: 'Entrar',
    logout: 'Sair',
    connectionFail: 'Não foi possível realizar o login.',
  },
  menu: {
    vixen: 'Vixen',
    coupons: 'Cupons',
    customers: 'Migrar clientes',
    products: 'Produtos',
    services: 'Serviços',
    combos: 'Combos',
    reset: 'Resetar senhas',
    productPurchase: 'Ajustes de estoque',
    config: 'Configurações',
  },
  home: {
    title: 'Página inicial',
    coupons: 'Gerar cupons',
    customers: 'Migrar clientes',
    products: 'Atualizar preços',
    services: 'Atualizar preços dos serviços',
    combos: 'Atualizar preços dos combos',
    reset: 'Resetar senhas',
    productPurchase: 'Ajustes de estoque',
    config: 'Configurações',
  },
  vixen: {
    info: {
      send: 'Salvando...',
      success: 'Salvo com sucesso',
      error: 'Ocorreu um erro',
    },
    tabs: {
      products: 'Produtos',
      paymentMethods: 'Métodos de pagamento',
      sync: 'Sincronizar',
    },
  },
  coupons: {
    add: 'Adicionar cupons',
    generator: 'Gerar cupons',
    allCoupons: 'Todos os cupons',
    selectProducts: 'Selecione os produtos',
    button: 'Gerar',
    copy: 'Copiar',
    copyAll: 'Copiar todos',
    copyCoupons: 'Copiar cupons',
    seeCoupons: 'Ver cupons',
    isUsed: 'Usado',
    discountAll: 'Aplicar a todos ',
    modal: {
      title: 'Remover cupom',
      remove: 'Remover',
      description: 'Tem certeza que deseja remover este cupom {code}?',
    },
    notification: {
      success: {
        generate: 'Cupo{pluralCoupon} gerado{pluralGenerate} com sucesso',
      },
      error: {
        products: 'Não foi possível carregar os produtos.',
        coupons: 'Não foi possível carregar os cupons.',
        generate: 'Não foi possível gerar o cupom.',
        remove: 'Não foi possível remover o cupom.',
      },
    },
    table: {
      products: 'Produtos',
      quantity: 'Quantidade',
      discount: 'Desconto',
      value: 'Valor',
      coupons: 'Cupons',
    },
  },
  units: {
    title: 'Lista de unidades',
    table: {
      unit: 'Unidade',
      email: 'E-mail',
    },
    notification: {
      error: {
        units: 'Não foi possível carregar as unidades.',
      },
    },
  },
  customers: {
    title: 'Migrar clientes',
    form: {
      identifier: 'Informe o documento',
      submit: 'Buscar',
      validate: 'Documento inválido',
    },
    notification: {
      migrate: {
        error: 'Não foi possível migrar o cliente.',
        success: 'Cliente migrado com sucesso.',
      },
      search: {
        error: 'Não foi possível carregar os dados.',
      },
    },
    table: {
      identifier: 'Documento',
      name: 'Nome',
      originUnit: 'Unidade atual',
      select: 'Escolha a nova unidade',
    },
    modal: {
      confirmation: 'Deseja realmente migrar a cliente <b>{customer}</b> da unidade <b>{from}</b> para a unidade <b>{to}</b>?',
      cancel: 'Cancelar',
      confirm: 'Ok',
    },
  },
  products: {
    title: 'Atualizar preços da unidade',
    button: 'Salvar',
    notification: {
      error: {
        load: 'Não foi possível carregar os produtos.',
        save: 'Ocorreu um erro',
      },
      info: {
        send: 'Salvando...',
        success: 'Salvo com sucesso',
      },
    },
    table: {
      products: 'Produtos',
      price: 'Preço',
      actions: 'Ações',
    },
  },
  services: {
    title: 'Atualizar preços da unidade',
    button: 'Salvar',
    notification: {
      error: {
        load: 'Não foi possível carregar os serviços.',
        save: 'Ocorreu um erro',
      },
      info: {
        send: 'Salvando...',
        success: 'Salvo com sucesso',
      },
    },
    table: {
      products: 'Serviços',
      price: 'Preço',
      actions: 'Ações',
    },
  },
  combos: {
    title: 'Atualizar preços da unidade',
    button: 'Salvar',
    notification: {
      error: {
        load: 'Não foi possível carregar os combos.',
        save: 'Ocorreu um erro',
      },
      info: {
        send: 'Salvando...',
        success: 'Salvo com sucesso',
      },
    },
    table: {
      products: 'Combos',
      price: 'Preço',
      actions: 'Ações',
    },
  },
  config: {
    title: 'Configurações da unidade',
    button: 'Salvar',
    notification: {
      error: {
        fetch: 'Não foi possível carregar as configurações.',
        save: 'Não foi possível salvar as configurações.',
      },
      success: 'Salvo com sucesso',
    },
    form: {
      minimumStock: 'Estoque mínimo',
      maximumProductDiscount: 'Desconto máximo por produto (%)',
      requireSerialNumber: 'Número de série obrigatório',
      sendItemsRevenueService: 'Enviar serviços para sistema de faturamento',
      identifierTypes: 'Outros documentos aceitos',
      maximumCourtesyServices: 'Porcentagem máxima de cortesias (%)',
      enableTimeline: 'Habilitar linha do tempo',
      submit: 'Salvar',
      validate: 'Campo obrigatório',
    },
    identifierTypes: {
      0: 'CPF',
      1: 'NIF',
      2: 'RUC',
      3: 'PY',
      4: 'US',
      5: 'CUI',
      6: 'DUI',
    },
  },
  reset: {
    title: 'Resetar senhas',
    manager: 'Resetar senha de gerente',
    user: 'Resetar senha de acesso',
    confirm: 'Clique novamente para confirmar',
    notification: {
      error: 'Não foi possível resetar a senha',
      success: 'Senha resetada com sucesso',
    },
  },
  productPurchase: {
    title: 'Ajustes de estoque da unidade',
    button: 'Salvar',
    addNew: 'Novo',
    switch: 'Mostrar compras na loja',
    remove: 'Remover',
    confirm: 'Clique novamente para confirmar',
    notification: {
      error: {
        load: 'Não foi possível carregar os produtos.',
        save: 'Ocorreu um erro',
        delete: 'Não foi possível deletar.',
      },
      success: 'Salvo com sucesso',
      delete: 'Removido com sucesso',
    },
    modal: {
      title: 'Produtos',
      add: 'Novo ajuste de estoque',
      quantity: 'Quantidade',
      filter: 'Filtrar',
      form: {
        type: 'Tipo de ajuste',
        products: 'Listagem de produtos',
        submit: 'Salvar',
        nf: 'Nota fiscal',
        options: {
          1: 'Entrada de estoque - Compra',
          2: 'Ajuste de estoque - Entrada',
          '-2': 'Ajuste de estoque - Saída',
          3: 'Entrada de estoque - Legado',
        },
      },
      confirmation: {
        message: 'Você tem certeza que deseja adicionar esse ajuste de estoque?',
        confirm: 'Confirmar',
        cancel: 'Cancelar',
      },
      errorMessage: 'Campo obrigatório',
    },
    table: {
      value: 'Valor',
      nf: 'NF',
      orderNumber: 'Pedido',
      createdAt: 'Criado em',
      approvedAt: 'Data da entrada',
      actions: 'Ações',
      type: 'Tipo do ajuste',
    },
  },
  table: {
    of: 'de',
    items: 'itens',
  },
  columnSearch: {
    search: 'Buscar',
    reset: 'Limpar',
  },
}

export default {
  locale: 'pt-BR',
  label: 'Português (Brasil)',
  country: 'BR',
  currency: 'BRL',
  dictionary,
}
