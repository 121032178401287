import { types } from './actions'

const INITIAL_STATE = {
  data: null,
  currentTab: null,
  copy: null,
  loading: false,
  statusRemove: {
    loading: false,
    close: false,
  },
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_ALL:
      return {
        ...state,
        data: action.payload,
      }

    case types.SET_LOADING:
      return {
        ...state,
        loading: action.meta.loading,
      }

    case types.COPY_COUPONS:
      return {
        ...state,
        copy: action.payload,
      }

    case types.SET_TAB:
      return {
        ...state,
        currentTab: action.meta.tab,
      }

    case types.PREPARE_REMOVE:
      return {
        ...state,
        statusRemove: action.payload,
      }

    default:
      return state
  }
}
