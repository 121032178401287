import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Modal } from 'antd'
import { t } from 'ab18n'

import { ListUnits } from 'pages'
import { actions } from './actions'
import './Password.sass'

const Action = ({ id, onClick }) => (
  <Button icon="lock" onClick={() => onClick(id)} />
)

const mapStateToProps = ({ units, reset }) => ({ units, reset })
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch)

class Coupons extends Component {
  state = {
    selectedUnit: null,
    openModal: false,
    showConfirm: false,
    type: null,
  }

  showModal = (id) => {
    this.setState({
      openModal: true,
      selectedUnit: id,
    })
  }

  closeModal = () => {
    this.setState({
      openModal: false,
      selectedUnit: null,
    })
  }

  buttonConfirm = (type) => {
    this.setState({
      showConfirm: true,
      type,
    })
  }

  confirmDelete = () => {
    setTimeout(() => this.setState({ showConfirm: false, type: null }), 2000)
  }

  render() {
    const {
      openModal,
      selectedUnit,
      showConfirm,
      type,
    } = this.state
    const {
      resetLoginPassword,
      resetManagerPassword,
      units: {
        data,
      },
      reset: {
        loading,
      },
    } = this.props

    const unitName = selectedUnit
      ? (data || []).filter(item => item.id === selectedUnit)[0].email
      : ''

    return (
      <Fragment>
        <ListUnits action={id => <Action id={id} onClick={this.showModal} />} />
        <Modal
          title={(
            `${t('reset.title')}: ${unitName}`
          )}
          width="50%"
          visible={openModal}
          onCancel={() => this.closeModal()}
          footer={false}
        >
          <div className="reset-options">
            <div className="manager">
              <Button
                icon={
                  showConfirm && type === 'manager' ? 'exclamation' : 'lock'
                }
                type="danger"
                className={
                  showConfirm && type === 'manager' ? 'confirmation' : ''
                }
                onClick={() => (
                  showConfirm
                    ? resetManagerPassword(
                      selectedUnit,
                      { after: this.confirmDelete },
                    ) : this.buttonConfirm('manager')
                )}
                onMouseLeave={() => !loading && this.confirmDelete()}
                loading={type === 'manager' && loading}
              >
                {
                  showConfirm && type === 'manager'
                    ? t('reset.confirm')
                    : t('reset.manager')
                }
              </Button>
            </div>
            <div className="user">
              <Button
                icon={showConfirm && type === 'user' ? 'exclamation' : 'lock'}
                type="danger"
                className={showConfirm && type === 'user' ? 'confirmation' : ''}
                onClick={() => (
                  showConfirm
                    ? resetLoginPassword(
                      selectedUnit,
                      { after: this.confirmDelete },
                    ) : this.buttonConfirm('user')
                )}
                onMouseLeave={() => !loading && this.confirmDelete()}
                loading={type === 'user' && loading}
              >
                {
                  showConfirm && type === 'user'
                    ? t('reset.confirm')
                    : t('reset.user')
                }
              </Button>
            </div>
          </div>
        </Modal>
      </Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Coupons)
