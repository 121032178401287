import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { t } from 'ab18n'
import {
  Card,
  Transfer,
  Button,
  Icon,
  Spin,
  Tabs,
  Modal,
  Tooltip,
} from 'antd'

import { TableComponent } from 'components'
import getColumnSearchProps from 'shared/getColumnSearchProps'
import { actions as actionsUnits } from 'pages/units/actions'
import { actions } from './actions'

const { TabPane } = Tabs

const columns = () => ([
  {
    title: '#',
    dataIndex: 'id',
    key: 'id',
    width: 50,
  },
  {
    title: t('coupons.table.products'),
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
    ...getColumnSearchProps('name'),
  },
  {
    title: t('coupons.table.value'),
    dataIndex: 'discount',
    align: 'right',
    sorter: (a, b) => a.discount.localeCompare(b.discount),
  },
  {
    title: t('coupons.table.coupons'),
    dataIndex: 'code',
    align: 'right',
    className: 'code',
    sorter: (a, b) => a.code.localeCompare(b.code),
    ...getColumnSearchProps('code'),
  },
  {
    title: '',
    dataIndex: 'action',
    className: 'nowrap',
    align: 'right',
    width: 40,
  },
])

const allCoupons = listCoupons => (
  (listCoupons || []).map(item => ([
    `Nome: ${item.name}, Desconto: ${item.discount}, Cupom: ${item.code}, Usado: ${item.isUsed ? 'Sim' : 'Não'}`,
  ])).join('\r\n')
)

const Actions = ({ item, remove }) => (
  <div className="coupons-actions">
    {
      item.isUsed
      && (
        <Tooltip title={t('coupons.isUsed')}>
          <Icon type="check" />
        </Tooltip>
      )
    }
    <Tooltip title={t('coupons.copy')}>
      <CopyToClipboard
        text={`${item.code} ${item.product && item.product.name}`}
      >
        <Button
          size="small"
          icon="copy"
        />
      </CopyToClipboard>
    </Tooltip>
    <Tooltip title={t('coupons.modal.remove')}>
      <Button
        size="small"
        icon="delete"
        type="danger"
        onClick={() => remove(item)}
      />
    </Tooltip>
  </div>
)

const mapStateToProps = ({ units, coupons }) => ({ units, coupons })
const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...actionsUnits,
}, dispatch)

class CouponsProducts extends Component {
  state = {
    targetKeys: [],
    showModal: false,
    code: null,
    itemToBeDeleted: {},
  }

  componentDidMount() {
    const { units, coupons, fetchUnits } = this.props

    if (!units.data) {
      fetchUnits()
    }

    this.fetchTab('coupons' || coupons.currentTab)
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.coupons.currentTab) {
      const { changeTab, coupons } = this.props
      changeTab('coupons' || coupons.currentTab)
    }
  }

  componentWillUnmount() {
    const { changeTab } = this.props
    changeTab(null)
  }

  fetchTab = (tab) => {
    const {
      coupons,
      fetchProducts,
      fetchCoupons,
      match,
    } = this.props
    const params = tab || coupons.currentTab
    const unitId = match.params.id

    if (params === 'products') {
      fetchProducts(unitId)
    } else {
      fetchCoupons(unitId)
    }
  }

  handleChange = (targetKeys) => {
    this.setState({ targetKeys })
  }

  renderItem = item => ({
    label: <span>{ item.name }</span>,
    value: item.name,
  })

  changeTab = (tab = 'coupons') => {
    const { changeTab } = this.props
    changeTab(tab)
    this.fetchTab(tab)
  }

  remove = (item) => {
    this.setState({
      code: item.code,
      itemToBeDeleted: item,
    })
    this.toggleModal()
  }

  handleModalCancel = () => {
    this.setState({ showModal: false })
  }

  handleModalOk = () => {
    const { removeCoupon, match } = this.props
    const { itemToBeDeleted } = this.state
    const unitId = match.params.id

    removeCoupon(itemToBeDeleted, unitId)
    this.toggleModal()
  }

  toggleModal() {
    this.setState(prevState => ({ showModal: !prevState.showModal }))
  }

  render() {
    const { targetKeys, showModal, code } = this.state
    const { units, coupons, match } = this.props
    const { data, loading } = coupons
    let unitEmail = ''

    if (units.data && match.params.id) {
      unitEmail = units.data.find(unit => unit.id === +match.params.id).email
    }

    const listProducts = data && data.map(product => ({
      ...product,
      key: product.id,
    }))

    const listCoupons = data && data.map(coupon => ({
      ...coupon,
      key: coupon.id,
      name: coupon.product && coupon.product.name,
      discount: `${coupon.discount || ''}%`,
      action: <Actions
        item={coupon}
        edit={this.edit}
        remove={this.remove}
      />,
    }))

    return (
      <div className="coupons-products">
        <Card title={unitEmail}>
          {
            coupons.currentTab
            && (
              <Tabs
                defaultActiveKey={coupons.currentTab}
                onChange={this.changeTab}
                tabBarExtraContent={
                  coupons.currentTab === 'coupons'
                  && (
                    <Tooltip title={t('coupons.copyAll')}>
                      <CopyToClipboard
                        text={allCoupons(listCoupons)}
                      >
                        <Button
                          size="small"
                          icon="copy"
                        />
                      </CopyToClipboard>
                    </Tooltip>
                  )
                }
              >
                <TabPane tab={t('coupons.allCoupons')} key="coupons">
                  <TableComponent
                    columns={columns()}
                    loading={loading}
                    dataSource={listCoupons}
                  />
                </TabPane>
                <TabPane tab={t('coupons.selectProducts')} key="products">
                  <Spin spinning={loading}>
                    <Transfer
                      showSearch
                      dataSource={listProducts || []}
                      targetKeys={targetKeys}
                      onChange={this.handleChange}
                      render={this.renderItem}
                    />
                    <div className="coupons-button">
                      <Link
                        to={{
                          pathname: `${match.url}/add`,
                          state: {
                            unitEmail,
                            targetKeys,
                          },
                        }}
                      >
                        <Button
                          type="primary"
                          disabled={!targetKeys.length}
                        >
                          { t('coupons.add') }
                          <Icon type="arrow-right" />
                        </Button>
                      </Link>
                    </div>
                  </Spin>
                </TabPane>
              </Tabs>
            )
          }
        </Card>
        <Modal
          okType="danger"
          title={t('coupons.modal.title')}
          okText={t('coupons.modal.remove')}
          visible={showModal || coupons.statusRemove.loading}
          onOk={this.handleModalOk}
          onCancel={this.handleModalCancel}
          okButtonProps={{ loading: coupons.statusRemove.loading }}
        >
          <h3
            /* eslint-disable react/no-danger */
            dangerouslySetInnerHTML={{
              __html: t('coupons.modal.description', { code }),
            }}
            /* eslint-enable react/no-danger */
          />
        </Modal>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponsProducts)
