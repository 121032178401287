import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import { notification } from 'antd'
import { t } from 'ab18n'

import api from 'services/api'
import { actions, types } from './actions'

function* fetchProductPurchaseSaga(action) {
  const { unitId } = action.meta
  yield put(actions.setLoading(true))

  try {
    const response = yield call(
      api.get,
      `admin/units/${unitId}/product-purchases`,
    )
    yield put(actions.setAll(response.data))
  } catch (error) {
    notification.error({
      message: t('productPurchase.notification.error.load'),
      duration: null,
    })
    yield put(actions.setAll(null))
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* sendProductPurchaseSaga(action) {
  const { payload } = action
  const { unitId, after } = action.meta
  yield put(actions.setLoading(true))

  try {
    yield call(
      api.post,
      `admin/units/${unitId}/product-purchases`,
      payload,
    )
    notification.success({
      message: t('productPurchase.notification.success'),
      duration: null,
    })
    after()
    yield put(actions.fetchProductPurchase(unitId))
  } catch (error) {
    notification.error({
      message: t('productPurchase.notification.error.load'),
      duration: null,
    })
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* deleteProductPurchaseSaga(action) {
  const { payload, meta: { unitId, onSuccess } } = action

  try {
    yield call(api.delete, `admin/units/${unitId}/product-purchases/${payload}`)
    notification.success({
      message: t('productPurchase.notification.delete'),
      duration: null,
    })
    onSuccess()
    yield put(actions.fetchProductPurchase(unitId))
  } catch (error) {
    notification.error({
      message: t('productPurchase.notification.error.delete'),
      duration: null,
    })
  }
}

export default function* () {
  yield all([
    takeLatest(types.FETCH_PRODUCT_PURCHASE, fetchProductPurchaseSaga),
    takeLatest(types.SEND_PRODUCT_PURCHASE, sendProductPurchaseSaga),
    takeLatest(types.DELETE_PRODUCT_PURCHASE, deleteProductPurchaseSaga),
  ])
}
