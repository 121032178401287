import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { t, c, n } from 'ab18n'
import { Card, List } from 'antd'

import { actions as actionLogin } from 'pages/auth/actions'
import { actions as actionsUnits } from 'pages/units/actions'
import RenderList from 'components/RenderList'
import { actions } from './actions'

const mapStateToProps = ({ services, units }) => ({ services, units })
const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...actionLogin,
  ...actionsUnits,
}, dispatch)

class ServicesPrices extends Component {
  componentDidMount() {
    const {
      units, match, fetchServices, fetchUnits,
    } = this.props
    const unitId = match.params.id

    if (!units.data) {
      fetchUnits()
    }

    fetchServices(unitId)
  }

  handleInputChange = (item, price) => {
    const { postItem, match } = this.props
    const unitId = match.params.id

    if (item.price !== n(price)) {
      postItem({
        ...item,
        price: n(price),
        unitId,
      })
    }
  }

  handleSwitchChange = (item, value) => {
    const { postItem, match } = this.props
    const unitId = match.params.id

    if (item.isActive !== value) {
      postItem({
        ...item,
        isActive: value,
        unitId,
      })
    }
  }

  render() {
    const { services, units, match } = this.props
    let unitEmail = ''

    if (units.data && match.params.id) {
      unitEmail = units.data.find(unit => unit.id === +match.params.id).email
    }

    return (
      <Card title={`${t('services.title')}: ${unitEmail}`}>
        <div className="list">
          {
            services.data
            && (
              <List
                size="small"
                dataSource={services.data}
                loading={services.loading}
                renderItem={item => (
                  <RenderList
                    {...item}
                    hasActive
                    value={c(item.price)}
                    width={150}
                    type="currency"
                    handleInputChange={this.handleInputChange}
                    handleSwitchChange={this.handleSwitchChange}
                  />
                )}
              />
            )
          }
        </div>
      </Card>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesPrices)
