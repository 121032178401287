import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { t } from 'ab18n'

import api from 'services/api'
import { actions, types } from './actions'

function* fetchTabSaga(action) {
  yield put(actions.setLoading(true))

  try {
    const response = yield call(api.get, `admin/vixen/${action.meta.tab}`)
    yield put(actions.setAll(response.data.map(item => ({
      ...item,
      id: item.productId || item.paymentMethodId,
    }))))
  } catch (error) {
    yield put(actions.setAll(null, error))
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* postItemSaga(action) {
  const { id, vixenId, currentTab } = action.payload

  yield put(actions.setItem({
    ...action.payload,
    info: {
      type: '',
      message: t('vixen.info.send'),
    },
  }))

  try {
    let response
    let params
    if (action.payload.hasVixexId === null) {
      if (currentTab === 'products') {
        params = { product: id, vixenId }
      } else {
        params = { paymentMethod: id, vixenId }
      }

      response = yield call(
        api.post,
        `admin/vixen/${currentTab}`,
        { ...params },
      )
    } else {
      response = yield call(
        api.put,
        `admin/vixen/${currentTab}/${id}`,
        { vixenId },
      )
    }

    const res = {
      ...response,
      id: response.productId || response.paymentMethodId,
    }

    yield put(actions.setItem({
      ...res,
      info: {
        type: 'success',
        message: t('vixen.info.success'),
      },
    }))
    yield delay(2000)
    yield put(actions.setItem({
      ...res,
      info: {
        type: '',
        message: '',
      },
    }))
  } catch (error) {
    const errorResponse = Object.values(error.response.data.errors).join(', ')
    yield put(actions.setItem({
      ...action.payload,
      info: {
        type: 'error',
        message: errorResponse || t('vixen.info.error'),
      },
    }))
  }
}

export default function* () {
  yield all([
    takeLatest(types.FETCH_TAB, fetchTabSaga),
    takeLatest(types.POST_ITEM, postItemSaga),
  ])
}
