import React, { Component } from 'react'
import { t } from 'ab18n'
import {
  Form,
  Input,
  Button,
} from 'antd'

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

class FormSearch extends Component {
  componentDidMount() {
    const { form } = this.props
    form.validateFields()
  }

  handleSubmit = (e) => {
    const { form, submit } = this.props

    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        const { identifier } = values

        submit(identifier)
      }
    })
  }

  render() {
    const {
      form: {
        getFieldDecorator,
        getFieldsError,
        getFieldError,
        isFieldTouched,
      },
    } = this.props

    const identifierError = isFieldTouched('identifier')
      && getFieldError('identifier')

    return (
      <Form layout="inline" onSubmit={this.handleSubmit}>
        <Form.Item
          validateStatus={identifierError ? 'error' : ''}
          help={identifierError || ''}
        >
          {
            getFieldDecorator(
              'identifier',
              {
                rules: [
                  {
                    required: true,
                    message: t('customers.form.validate'),
                  },
                ],
              },
            )(
              <Input
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/\D/g, '')
                }}
                placeholder={t('customers.form.identifier')}
              />,
            )
          }
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={hasErrors(getFieldsError())}
          >
            { t('customers.form.submit') }
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

export default Form.create({ name: 'customer_search' })(FormSearch)
