import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import { notification } from 'antd'
import { t } from 'ab18n'

import api from 'services/api'
import { actions, types } from './actions'

function* fetchProductsSaga(action) {
  const { unitId } = action.meta
  yield put(actions.setLoading(true))

  try {
    const response = yield call(api.get, `admin/units/${unitId}/products`)
    yield put(actions.setAll(response.data))
  } catch (error) {
    notification.error({
      message: t('coupons.notification.error.products'),
      duration: null,
    })
    yield put(actions.setAll(null))
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* fetchCouponsSaga(action) {
  const { unitId } = action.meta
  yield put(actions.setLoading(true))

  try {
    const response = yield call(api.get, `admin/units/${unitId}/coupons`)
    yield put(actions.setAll(response.data))
  } catch (error) {
    notification.error({
      message: t('coupons.notification.error.coupons'),
      duration: null,
    })
    yield put(actions.setAll(null))
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* generateCouponsSaga({ payload, meta: { unitId } }) {
  yield put(actions.setLoading(true))

  try {
    const response = yield call(
      api.post,
      `admin/units/${unitId}/coupons`,
      { ...payload },
    )
    notification.success({
      message: t('coupons.notification.success.generate', {
        pluralCoupon: payload.length > 1 ? 'ns' : 'm',
        pluralGenerate: payload.length > 1 ? 's' : '',
      }),
      duration: 1.5,
    })
    yield put(actions.setTab('coupons'))
    yield put(actions.copyCoupons(response.data))
  } catch (error) {
    notification.error({
      message: t('coupons.notification.error.generate'),
      duration: null,
    })
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* changeTabSaga(action) {
  yield put(actions.setTab(action.meta.tab))
}

function* removeCouponSaga(action) {
  const {
    meta: { unitId },
    payload: { id },
  } = action

  yield put(actions.prepareRemove({ loading: true }))
  try {
    yield call(api.delete, `admin/units/${unitId}/coupons/${id}`)
    yield put(actions.fetchCoupons(unitId))
  } catch (error) {
    notification.error({
      message: t('coupons.notification.error.remove'),
      duration: null,
    })
  } finally {
    yield put(actions.prepareRemove({ loading: false }))
  }
}

export default function* () {
  yield all([
    takeLatest(types.FETCH_PRODUCTS, fetchProductsSaga),
    takeLatest(types.FETCH_COUPONS, fetchCouponsSaga),
    takeLatest(types.GENERATE_COUPONS, generateCouponsSaga),
    takeLatest(types.CHANGE_TAB, changeTabSaga),
    takeLatest(types.REMOVE_COUPON, removeCouponSaga),
  ])
}
