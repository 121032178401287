const search = (searchTerm, data) => {
  const terms = term => (`
    ${term.name}
  `).normalize('NFD').replace(/[^\w\s]+/g, '').toLowerCase()

  const result = data.filter(term => (
    terms(term)
      .indexOf(
        searchTerm.normalize('NFD').replace(/[^\w\s]+/g, '').toLowerCase(),
      ) >= 0
  ))

  return result
}

export default search
