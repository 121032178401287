import { typeActions } from 'state/util'

const { actions, types } = typeActions('reset', {
  resetManagerPassword: (data, meta) => ({ payload: data, meta }),
  resetLoginPassword: (data, meta) => ({ payload: data, meta }),
  setLoading: value => ({ meta: { loading: value } }),
})

export {
  actions,
  types,
}
