import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { t } from 'ab18n'
import { Card, Button } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { TableComponent } from 'components'
import { actions as actionLogin } from 'pages/auth/actions'
import { actions } from './actions'
import CouponInput from './CouponInput'

const columns = () => ([
  {
    title: '#',
    dataIndex: 'id',
    key: 'id',
    width: 50,
  },
  {
    title: t('coupons.table.products'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: t('coupons.table.quantity'),
    dataIndex: 'quantityAction',
    align: 'right',
  },
  {
    title: t('coupons.table.discount'),
    dataIndex: 'discountAction',
    align: 'right',
  },
])

const mapStateToProps = ({ coupons }) => ({ coupons })
const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...actionLogin,
}, dispatch)

class CouponsAdd extends Component {
  state = {
    products: [],
    seeCoupons: false,
    loading: false,
    allValueDiscount: null,
  }

  componentDidMount() {
    const { coupons, location, goHome } = this.props

    if (!location.state || !coupons.data) {
      goHome()
    } else if (location.state && coupons.data) {
      this.setProducts(coupons, location)
    }
  }

  setProducts = (coupons, location) => {
    const products = coupons.data.map((product) => {
      if (!location.state.targetKeys.includes(product.id)) {
        return false
      }

      return {
        ...product,
        quantityAction: <CouponInput
          type="quantity"
          item={product}
          value={null}
          handleChange={this.handleChange}
        />,
        discountAction: <CouponInput
          type="discount"
          item={product}
          value={null}
          handleChange={this.handleChange}
        />,
      }
    }).filter(item => item)

    this.setState({ loading: true, products })

    setTimeout(() => {
      this.setState({ loading: false })
    }, 500)
  }

  handleChange = (type, item, value) => {
    const { products } = this.state
    const index = products.findIndex(product => product.id === item.id)
    let items = products
    let allValueDiscount = null

    if (Array.isArray(item)) {
      allValueDiscount = +value
      items = item.map(el => ({
        ...el,
        [type]: +value,
        [`${type}Action`]: <CouponInput
          type={type}
          item={el}
          value={+value}
          handleChange={this.handleChange}
        />,
      }))

      this.setState({ allValueDiscount, products: items })
    } else {
      if (index < 0) {
        return items
      }

      items = [
        ...items.slice(0, index),
        Object.assign(items[index], {
          [type]: +value,
          [`${type}Action`]: <CouponInput
            type={type}
            item={item}
            value={+value}
            handleChange={this.handleChange}
          />,
        }),
        ...items.slice(index + 1),
      ]

      this.setState({
        allValueDiscount: null,
        products: items,
      })
    }

    return items
  }

  handleSubmit = () => {
    const { generateCoupons, match } = this.props
    const { products } = this.state
    const data = products.map(item => ({
      product: item.id,
      quantity: item.quantity,
      discount: item.discount,
    }))

    generateCoupons(data, match.params)
  }

  goToCoupons = () => {
    const { setTab } = this.props

    setTab('coupons')
    this.setState({ seeCoupons: true })
  }

  render() {
    const { coupons, match, location } = this.props
    const {
      allValueDiscount,
      seeCoupons,
      loading,
      products,
    } = this.state
    const disabled = !!products.length
      && products.every(item => item.quantity > 0 && item.discount > 0)

    const unitId = match.params.id
    const copy = coupons.copy && coupons.copy.map(item => (
      `${item.code} ${item.product.name}`)).join('\n')

    return (
      <Card
        title={`${t('coupons.generator')}: ${location.state.unitEmail}`}
        extra={(
          <Fragment>
            { t('coupons.discountAll') }
            <CouponInput
              type="discount"
              item={products}
              value={allValueDiscount}
              handleChange={this.handleChange}
            />
          </Fragment>
        )}
      >
        <TableComponent
          columns={columns()}
          loading={loading}
          dataSource={products}
          footer={() => (
            <div className="coupons-button">
              {
                seeCoupons
                && (
                  <Link to={`/coupons/${unitId}`}>
                    { t('coupons.seeCoupons') }
                  </Link>
                )
              }
              {
                copy
                && (
                  <CopyToClipboard text={copy}>
                    <Button type="default" onClick={this.goToCoupons}>
                      { t('coupons.copyCoupons') }
                    </Button>
                  </CopyToClipboard>
                )
              }
              <Button
                type="primary"
                onClick={this.handleSubmit}
                disabled={!disabled}
                loading={coupons.loading}
              >
                { t('coupons.button') }
              </Button>
            </div>
          )}
        />
      </Card>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponsAdd)
