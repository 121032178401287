import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { t } from 'ab18n'
import { Card } from 'antd'

import { TableComponent } from 'components'
import getColumnSearchProps from 'shared/getColumnSearchProps'
import { actions } from './actions'

const columns = () => ([
  {
    title: '#',
    dataIndex: 'id',
    key: 'id',
    width: 50,
  },
  {
    title: t('units.table.email'),
    dataIndex: 'email',
    key: 'email',
    ...getColumnSearchProps('email'),
  },
  {
    title: '',
    dataIndex: 'action',
    className: 'nowrap',
    align: 'right',
    width: 40,
  },
])

const mapStateToProps = ({ units }) => ({ units })
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch)

class ListUnits extends Component {
  componentDidMount() {
    const { fetchUnits } = this.props
    fetchUnits()
  }

  render() {
    const { units, action } = this.props
    const { data, loading } = units

    const dataSource = data && data.map(unit => ({
      ...unit,
      action: action(unit.id),
    }))

    return (
      <Card title={t('units.title')}>
        <TableComponent
          columns={columns()}
          loading={loading}
          dataSource={dataSource}
        />
      </Card>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListUnits)
