import React, { Component } from 'react'
import { Button } from 'antd'
import { t } from 'ab18n'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import oauth from 'services/oauth'
import logo from 'assets/images/logo.png'
import { actions } from './actions'
import './Login.sass'

const mapStateToProps = state => ({ ...state })
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch)

class Login extends Component {
  async componentWillMount() {
    const isLoggedIn = await oauth.isLoggedIn()
    if (isLoggedIn) {
      const { goHome } = this.props
      goHome()
    }
  }

  componentDidMount() {
    if (window.gapi) {
      oauth.init()
    }
  }

  onClick = () => {
    const { setStatus } = this.props
    setStatus({
      loading: false,
      disabled: true,
      error: null,
    })
  }

  render() {
    const { auth } = this.props
    const { loading, disabled, error } = auth.status

    return (
      <div className="login">
        <div className="login-wrapper">
          <img src={logo} alt={t('general.logo')} />
          <h2>{ t('general.logo') }</h2>
          <Button
            block
            type="primary"
            id="login-btn"
            disabled={disabled}
            loading={loading}
            onClick={this.onClick}
          >
            { t('auth.login') }
          </Button>
          { error && <p>{ error }</p> }
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
