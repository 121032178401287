import React, { Component } from 'react'
import VMasker from 'vanilla-masker'
import { Input } from 'antd'

const defineMask = value => (
  value === 100 ? '999' : '99.99'
)

const format = (value, type) => (
  value && VMasker.toNumber(value)
    ? VMasker.toPattern(
      value,
      type === 'discount'
        ? defineMask(value)
        : '999',
    )
    : value
)

class CouponInput extends Component {
  onChangeValue = (value) => {
    const { type, item, handleChange } = this.props
    const valueFormated = format(+value.replace(/\D/g, ''), type)

    handleChange(type, item, valueFormated)
  }

  render() {
    const { type, value } = this.props

    return (
      <Input
        size="small"
        style={{ width: 100, textAlign: 'right' }}
        value={value}
        addonAfter={type === 'discount' ? '%' : null}
        onChange={e => this.onChangeValue(e.target.value)}
      />
    )
  }
}

export default CouponInput
