import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { t, c } from 'ab18n'
import moment from 'moment'
import {
  Card,
  Modal,
  Button,
  List,
  Tooltip,
  Badge,
  Switch,
} from 'antd'

import status from 'shared/status'
import { TableComponent } from 'components'
import getColumnSearchProps from 'shared/getColumnSearchProps'
import search from 'shared/search'
import { actions as actionLogin } from 'pages/auth/actions'
import { actions as actionsUnits } from 'pages/units/actions'
import { actions as productsActions } from 'pages/products/actions'
import { actions } from './actions'
import AddNew from './AddNew'
import './ProductPurchase.sass'

const columns = () => ([
  {
    dataIndex: 'typeFormatted',
    key: 'typeFormatted',
    align: 'center',
    width: 50,
  },
  {
    title: '#',
    dataIndex: 'id',
    key: 'id',
    width: 100,
  },
  {
    title: t('productPurchase.table.nf'),
    dataIndex: 'nf',
    key: 'nf',
    width: 200,
    ...getColumnSearchProps('nf'),
  },
  {
    title: t('productPurchase.table.orderNumber'),
    dataIndex: 'orderNumber',
    key: 'orderNumber',
    width: 200,
    ...getColumnSearchProps('orderNumber'),
  },
  {
    title: t('productPurchase.table.createdAt'),
    dataIndex: 'createdAtFormatted',
    key: 'createdAtFormatted',
    width: 200,
    sorter: (a, b) => a.createdAt > b.createdAt,
    ...getColumnSearchProps('createdAt'),
  },
  {
    title: t('productPurchase.table.approvedAt'),
    dataIndex: 'approvedAtFormatted',
    key: 'approvedAtFormatted',
    width: 200,
    align: 'center',
    sorter: (a, b) => a.approvedAt > b.approvedAt,
    ...getColumnSearchProps('approvedAt'),
  },
  {
    title: t('productPurchase.table.value'),
    dataIndex: 'value',
    key: 'value',
    align: 'right',
    width: 150,
  },
  {
    title: '',
    dataIndex: 'action',
    className: 'nowrap',
    align: 'right',
    width: 50,
  },
])

const mapStateToProps = ({ productPurchase, products, units }) => ({
  productPurchase,
  units,
  products,
})
const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...actionLogin,
  ...actionsUnits,
  fetchProducts: productsActions.fetchProducts,
}, dispatch)

class ListProductPurchase extends Component {
  state = {
    selectedPurchase: null,
    openModal: false,
    searchResult: null,
    newPurchase: {
      type: '2',
      products: [],
    },
    showStorePurchase: true,
    showConfirm: false,
    errorMessage: '',
  }

  componentDidMount() {
    const {
      units,
      products,
      match,
      fetchProductPurchase,
      fetchUnits,
      fetchProducts,
    } = this.props
    const unitId = match.params.id

    if (!units.data) {
      fetchUnits()
    }

    if (!products.data) {
      fetchProducts(unitId)
    }

    fetchProductPurchase(unitId)
  }

  toggleModal = (id = null) => {
    this.setState(prevState => ({
      openModal: !prevState.openModal,
      selectedPurchase: id,
      newPurchase: {
        type: '2',
        products: [],
      },
      searchResult: null,
    }))
  }

  setDataSource = data => data.map(item => ({
    ...item,
    value: c(item.value),
    orderNumber: item.type === 1 ? item.orderNumber : ' - ',
    typeFormatted: (
      <Tooltip
        placement="top"
        title={t(`productPurchase.modal.form.options.${item.type}`)}
      >
        <Badge color={status(item.type)} />
      </Tooltip>
    ),
    createdAtFormatted: moment(item.createdAt).format('L LTS'),
    approvedAtFormatted: item.approvedAt
      ? moment(item.approvedAt).format('L LTS')
      : '-',
    action: (
      <Button icon="arrow-right" onClick={() => this.toggleModal(item)} />
    ),
  }))

  handleChanges = (name, value) => {
    this.setState((prevState) => {
      const valuesFormatted = {
        products: [],
      }
      const { products } = prevState.newPurchase

      if (name === 'type') {
        valuesFormatted.type = value
      } else if (name === 'nf') {
        valuesFormatted.nf = value
      } else if (+value !== 0) {
        valuesFormatted.products = [{
          product: name.productId,
          quantity: value === '' ? 0 : +value,
        }]
      }

      return {
        newPurchase: {
          ...prevState.newPurchase,
          ...valuesFormatted,
          products: [
            ...products.filter(item => (
              item.product !== name.productId
            )),
            ...valuesFormatted.products,
          ],
        },
      }
    })
  }

  invalidForm = () => {
    const { newPurchase } = this.state

    return +newPurchase.type === 3 && (!newPurchase.nf || newPurchase.nf === '')
  }

  handleSubmit = () => {
    const { sendProductPurchase, match } = this.props
    const { newPurchase } = this.state
    const unitId = match.params.id

    this.setState({ errorMessage: '' })

    if (!this.invalidForm()) {
      sendProductPurchase(newPurchase, { unitId, after: this.toggleModal })
    } else {
      this.setState({ errorMessage: t('productPurchase.modal.errorMessage') })
    }
  }

  onSearch = (term) => {
    const { products: { data } } = this.props

    this.setState({
      searchResult: search(term, data),
    })
  }

  buttonConfirm = () => {
    this.setState({
      showConfirm: true,
    })
  }

  confirmDelete = () => {
    setTimeout(() => this.setState({ showConfirm: false }), 2000)
  }

  handleDelete = (id) => {
    const { deleteProductPurchase, match } = this.props
    const unitId = match.params.id

    deleteProductPurchase(id, { unitId, onSuccess: this.toggleModal })
  }

  render() {
    const {
      openModal,
      selectedPurchase,
      newPurchase,
      searchResult,
      showStorePurchase,
      showConfirm,
      errorMessage,
    } = this.state
    const {
      productPurchase,
      units,
      products,
      match,
    } = this.props
    let unitEmail = ''

    if (units.data && match.params.id) {
      unitEmail = units.data.find(unit => unit.id === +match.params.id).email
    }

    const data = (productPurchase.data || []).filter((item) => {
      if (!showStorePurchase) {
        return item.type !== 1
      }

      return item
    })

    return (
      <Card title={`${t('productPurchase.title')}: ${unitEmail}`}>
        <div className="add-action">
          <div className="switch">
            <span>{ t('productPurchase.switch') }</span>
            <Switch
              checked={showStorePurchase}
              onChange={value => this.setState({ showStorePurchase: value })}
            />
          </div>
          <Button
            type="primary"
            icon="plus"
            onClick={() => this.toggleModal()}
          >
            { t('productPurchase.addNew') }
          </Button>
        </div>
        <div className="list">
          <TableComponent
            rowKey="id"
            columns={columns()}
            loading={!!productPurchase.loading}
            dataSource={this.setDataSource(data)}
          />
        </div>
        <Modal
          title={
            t(`productPurchase.modal.${selectedPurchase ? 'title' : 'add'}`)
          }
          width="60%"
          visible={openModal}
          onCancel={() => this.toggleModal()}
          footer={false}
          className="product-purchase"
          keyboard={!!selectedPurchase}
        >
          <div
            className={
              selectedPurchase
                ? 'product-purchase-content' : 'add-product-purchase-content'
            }
          >
            {
              selectedPurchase && (
                <div className="view-product-purchase">
                  {
                    !selectedPurchase.approvedAt && (
                      <div className="button">
                        <Button
                          ghost={!showConfirm}
                          icon={showConfirm ? 'exclamation' : 'delete'}
                          type="danger"
                          onClick={() => (
                            showConfirm
                              ? this.handleDelete(selectedPurchase.id)
                              : this.buttonConfirm()
                          )}
                          onMouseLeave={() => this.confirmDelete()}
                        >
                          {
                            t(`productPurchase.${
                              showConfirm ? 'confirm' : 'remove'
                            }`)
                          }
                        </Button>
                      </div>
                    )
                  }
                  <List
                    size="small"
                    bordered
                    dataSource={selectedPurchase.products}
                    renderItem={item => (
                      <List.Item>
                        <List.Item.Meta title={item.name} />
                        {
                          `${
                            t('productPurchase.modal.quantity')
                          }: ${
                            item.quantity
                          }
                        `}
                      </List.Item>
                    )}
                  />
                </div>
              )
            }
            {
              !selectedPurchase && openModal && (
                <AddNew
                  newPurchase={newPurchase}
                  products={searchResult || products.data}
                  handleChanges={(name, value) => (
                    this.handleChanges(name, value)
                  )}
                  handleSubmit={() => this.handleSubmit()}
                  loading={productPurchase.loading}
                  search={term => this.onSearch(term)}
                  error={errorMessage}
                />
              )
            }
          </div>
        </Modal>
      </Card>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListProductPurchase)
