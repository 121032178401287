import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import { notification } from 'antd'
import { t } from 'ab18n'

import api from 'services/api'
import { actions, types } from './actions'

function* resetManagerPasswordSaga(action) {
  const { payload, meta: { after } } = action

  yield put(actions.setLoading(true))
  try {
    yield call(api.patch, `admin/units/${payload}/manager-password`)
    notification.success({
      message: t('reset.notification.success'),
      duration: null,
    })
    after()
  } catch (error) {
    notification.error({
      message: t('reset.notification.error'),
      duration: null,
    })
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* resetLoginPasswordSaga(action) {
  const { payload, meta: { after } } = action

  yield put(actions.setLoading(true))
  try {
    yield call(api.patch, `admin/units/${payload}/user-password`)
    notification.success({
      message: t('reset.notification.success'),
      duration: null,
    })
    after()
  } catch (error) {
    notification.error({
      message: t('reset.notification.error'),
      duration: null,
    })
  } finally {
    yield put(actions.setLoading(false))
  }
}

export default function* () {
  yield all([
    takeLatest(types.RESET_MANAGER_PASSWORD, resetManagerPasswordSaga),
    takeLatest(types.RESET_LOGIN_PASSWORD, resetLoginPasswordSaga),
  ])
}
