import React from 'react'
import { t } from 'ab18n'
import { Table } from 'antd'

const TableComponent = ({
  loading,
  columns,
  dataSource,
  pagination = true,
  ...rest
}) => (
  <div style={{ width: '100%', overflowX: 'auto' }}>
    <Table
      {...rest}
      size="middle"
      rowKey="id"
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      pagination={pagination && {
        showSizeChanger: true,
        defaultPageSize: 10,
        pageSizeOptions: ['10', '25', '50', '100'],
        total: dataSource && dataSource.length,
        showTotal: (total, range) => (
          `${range[0]}-${range[1]}
          ${t('table.of')}
          ${total} ${t('table.items')}`
        ),
      }}
    />
  </div>
)

export default TableComponent
