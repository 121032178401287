import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import { notification } from 'antd'
import { t } from 'ab18n'
import { delay } from 'redux-saga'

import api from 'services/api'
import { actions, types } from './actions'

function* fetchServicesSaga(action) {
  const { unitId } = action.meta
  yield put(actions.setLoading(true))

  try {
    const response = yield call(api.get, `admin/units/${unitId}/services`)
    yield put(actions.setAll(response.data))
  } catch (error) {
    notification.error({
      message: t('services.notification.error.load'),
      duration: null,
    })
    yield put(actions.setAll(null))
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* postItemSaga(action) {
  const {
    id, price, isActive, unitId,
  } = action.payload

  yield put(actions.setItem({
    ...action.payload,
    info: {
      type: '',
      message: t('services.notification.info.send'),
    },
  }))

  try {
    const response = yield call(
      api.put,
      `admin/units/${unitId}/services/${id}`,
      { price, isActive },
    )

    const res = {
      ...action.payload,
      price: response.price,
      id,
    }

    yield put(actions.setItem({
      ...res,
      info: {
        type: 'success',
        message: t('services.notification.info.success'),
      },
    }))
    yield delay(2000)
    yield put(actions.setItem({
      ...res,
      info: {
        type: '',
        message: '',
      },
    }))
  } catch (error) {
    const errorResponse = Object.values(error.response.data.errors).join(', ')
    yield put(actions.setItem({
      ...action.payload,
      info: {
        type: 'error',
        message: errorResponse || t('services.notification.error.save'),
      },
    }))
  }
}

export default function* () {
  yield all([
    takeLatest(types.FETCH_SERVICES, fetchServicesSaga),
    takeLatest(types.POST_ITEM, postItemSaga),
  ])
}
