import { all } from 'redux-saga/effects'

import authSaga from 'pages/auth/saga'
import vixenSaga from 'pages/vixen/saga'
import unitsSaga from 'pages/units/saga'
import couponsSaga from 'pages/coupons/saga'
import customerSaga from 'pages/customers/saga'
import productsSaga from 'pages/products/saga'
import servicesSaga from 'pages/services/saga'
import combosSaga from 'pages/combos/saga'
import resetSaga from 'pages/reset/saga'
import productPurchaseSaga from 'pages/productPurchase/saga'
import configSaga from 'pages/config/saga'

export default function* rootSaga() {
  yield all([
    authSaga(),
    vixenSaga(),
    unitsSaga(),
    couponsSaga(),
    customerSaga(),
    productsSaga(),
    servicesSaga(),
    combosSaga(),
    resetSaga(),
    productPurchaseSaga(),
    configSaga(),
  ])
}
