import React, { Component } from 'react'
import { List, Input, Switch } from 'antd'
import { c, n } from 'ab18n'
import './RenderList.sass'

class RenderList extends Component {
  state = {
    value: '',
    isActive: 1,
  }

  componentDidMount() {
    const {
      value,
      type,
      hasActive,
      isActive,
    } = this.props
    this.handleChange(value, type)
    if (hasActive) {
      this.setState({ isActive })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      value,
      type,
      isActive,
      hasActive,
    } = this.props

    if (prevProps.value !== value) {
      this.handleChange(value, type)
    }

    if (prevProps.isActive !== isActive && hasActive) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isActive })
    }
  }

  handleChange(value, type) {
    let currentValue = value

    if (type === 'currency') {
      currentValue = c(n(value))
    }

    if (type === 'number') {
      currentValue = typeof value === 'string'
        ? value.replace(/\D/g, '')
        : value
    }

    this.setState({ value: currentValue })
  }

  render() {
    const {
      id,
      name,
      info,
      type,
      width,
      handleInputChange,
      handleSwitchChange,
      hasActive = false,
    } = this.props
    const { value, isActive } = this.state

    return (
      <List.Item
        onFocus={(e) => { e.currentTarget.style.backgroundColor = '#E6F7FF' }}
        onBlur={(e) => { e.currentTarget.style.backgroundColor = '#FFFFFF' }}
      >
        <List.Item.Meta title={`${id} - ${name}`} />
        <div className="list-item">
          { info && <span className={info.type}>{ info.message }</span> }
          {
            hasActive && (
              <Switch
                className="switch-active"
                checked={!!isActive}
                onChange={e => handleSwitchChange(this.props, e)}
              />
            )
          }
          <Input
            size="small"
            style={{ width, textAlign: 'right' }}
            value={value}
            defaultValue={value}
            onChange={e => this.handleChange(e.target.value, type)}
            onBlur={e => handleInputChange(this.props, e.target.value)}
          />
        </div>
      </List.Item>
    )
  }
}

export default RenderList
