import { typeActions } from 'state/util'

const { actions, types } = typeActions('units', {
  fetchUnits: units => ({ payload: { units } }),
  setAll: (data = null, error = null) => ({
    payload: data,
    error,
  }),
  setLoading: value => ({ meta: { loading: value } }),
})

export {
  actions,
  types,
}
