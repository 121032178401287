import React from 'react'
import Highlighter from 'react-highlight-words'
import { t } from 'ab18n'
import {
  Input, Button, Icon,
} from 'antd'

let searchText
let searchInput

const handleSearch = (selectedKeys, confirm) => {
  const [text] = selectedKeys
  confirm()
  searchText = text
}

const handleReset = (clearFilters) => {
  clearFilters()
  searchText = ''
}

const getColumnSearchProps = dataIndex => ({
  filterDropdown: ({
    setSelectedKeys, selectedKeys, confirm, clearFilters,
  }) => (
    <div
      style={{
        padding: '8px',
        borderRadius: '4px',
        background: '#fff',
        boxShadow: '0 2px 8px rgba(0, 0, 0, .15)',
      }}
    >
      <Input
        ref={(node) => { searchInput = node }}
        placeholder={t('columnSearch.search')}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(
          e.target.value
            ? [e.target.value]
            : [],
        )}
        onPressEnter={() => handleSearch(selectedKeys, confirm)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => handleSearch(selectedKeys, confirm)}
        icon="search"
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        { t('columnSearch.search') }
      </Button>
      <Button
        onClick={() => handleReset(clearFilters)}
        size="small"
        style={{ width: 90 }}
      >
        { t('columnSearch.reset') }
      </Button>
    </div>
  ),
  filterIcon: filtered => (
    <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
  onFilter: (value, record) => (
    record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
  ),
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.select())
    }
  },
  render: text => (
    <Highlighter
      autoEscape
      highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
      searchWords={[searchText]}
      textToHighlight={text ? text.toString() : ''}
    />
  ),
})

export default getColumnSearchProps
