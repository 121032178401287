import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { t } from 'ab18n'
import { Card, Spin } from 'antd'

import { actions as actionLogin } from 'pages/auth/actions'
import { actions as actionsUnits } from 'pages/units/actions'
import ConfigForm from './ConfigForm'
import { actions } from './actions'
import './UnitConfig.sass'

const mapStateToProps = ({ config, units }) => ({ config, units })
const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...actionLogin,
  ...actionsUnits,
}, dispatch)

class UnitConfig extends Component {
  componentDidMount() {
    const {
      units, match, fetchConfig, fetchUnits,
    } = this.props
    const unitId = match.params.id

    if (!units.data) {
      fetchUnits()
    }

    fetchConfig(unitId)
  }

  handleSubmit = (values) => {
    const { match, config: { data }, saveConfig } = this.props
    const unitId = match.params.id

    const update = !!Object.entries(data).length

    saveConfig(values, { unitId, update })
  }

  render() {
    const { units, match, config: { data, loading } } = this.props
    let unitEmail = ''

    if (units.data && match.params.id) {
      unitEmail = units.data.find(unit => unit.id === +match.params.id).email
    }

    return (
      <Card
        title={`${t('config.title')}: ${unitEmail}`}
      >
        <Spin className="config-loading" spinning={loading}>
          {
            data && (
              <ConfigForm
                config={data}
                submit={this.handleSubmit}
              />
            )
          }
        </Spin>
      </Card>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnitConfig)
