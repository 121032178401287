import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button } from 'antd'

import { ListUnits } from 'pages'
import { actions } from './actions'

const Action = ({ id }) => (
  <Link to={`/products/${id}`}>
    <Button icon="arrow-right" />
  </Link>
)

const mapStateToProps = ({ products }) => ({ products })
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch)

class Products extends Component {
  render() {
    return <ListUnits action={id => <Action id={id} />} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Products)
