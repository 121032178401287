import React from 'react'
import { List } from 'antd'

import RenderList from './RenderList'

const ItemTab = ({
  data,
  loading,
  handleInputChange,
}) => (
  <div className="list">
    <List
      size="small"
      dataSource={data}
      loading={loading}
      renderItem={item => (
        <RenderList
          {...item}
          handleInputChange={handleInputChange}
        />
      )}
    />
  </div>
)

export default ItemTab
