import { combineReducers } from 'redux'

import auth from 'pages/auth/reducer'
import vixen from 'pages/vixen/reducer'
import units from 'pages/units/reducer'
import coupons from 'pages/coupons/reducer'
import customer from 'pages/customers/reducer'
import products from 'pages/products/reducer'
import services from 'pages/services/reducer'
import combos from 'pages/combos/reducer'
import reset from 'pages/reset/reducer'
import productPurchase from 'pages/productPurchase/reducer'
import config from 'pages/config/reducer'

export default combineReducers({
  auth,
  vixen,
  units,
  coupons,
  customer,
  products,
  services,
  combos,
  reset,
  productPurchase,
  config,
})
