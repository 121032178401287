import { types } from './actions'

const INITIAL_STATE = {
  data: null,
  loading: false,
  info: {
    type: '',
    message: '',
  },
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_ALL:
      return {
        ...state,
        data: action.payload,
      }

    case types.SET_ITEM: {
      const index = state.data.findIndex((item) => {
        const id = item.productId || item.paymentMethodId
        return id === action.payload.id
      })

      if (index < 0) {
        return state
      }

      return {
        ...state,
        data: [
          ...state.data.slice(0, index),
          Object.assign(action.payload, {
            info: {
              type: action.payload.info.type,
              message: action.payload.info.message,
            },
          }),
          ...state.data.slice(index + 1),
        ],
      }
    }

    case types.SET_LOADING:
      return {
        ...state,
        loading: action.meta.loading,
      }

    default:
      return state
  }
}
