import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import { notification } from 'antd'
import { t } from 'ab18n'

import api from 'services/api'
import { actions, types } from './actions'

function* fetchUnitsSaga() {
  yield put(actions.setLoading(true))

  try {
    const response = yield call(api.get, 'admin/units')
    yield put(actions.setAll(response.data))
  } catch (error) {
    notification.error({
      message: t('units.notification.error.units'),
      duration: null,
    })
  } finally {
    yield put(actions.setLoading(false))
  }
}

export default function* () {
  yield all([
    takeLatest(types.FETCH_UNITS, fetchUnitsSaga),
  ])
}
