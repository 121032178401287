import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import { notification } from 'antd'
import { t } from 'ab18n'

import api from 'services/api'
import { actions, types } from './actions'

function* searchCustomerSaga(action) {
  const { payload } = action

  yield put(actions.setLoading(true))
  try {
    const response = yield call(api.get, `admin/customers/${payload}`)
    yield put(actions.setCustomer(response))
  } catch (error) {
    notification.error({
      message: t('customers.notification.search.error'),
      duration: null,
    })
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* migrateCustomerSaga(action) {
  const { payload: { customer, unit }, meta: { after } } = action

  yield put(actions.setLoading(true))
  try {
    yield call(
      api.patch,
      `admin/customers/${customer}`,
      { originalUnit: unit },
    )
    notification.success({
      message: t('customers.notification.migrate.success'),
      duration: null,
    })
    yield put(actions.searchCustomer(customer))
    after()
  } catch (error) {
    notification.error({
      message: t('customers.notification.migrate.error'),
      duration: null,
    })
  } finally {
    yield put(actions.setLoading(false))
  }
}

export default function* () {
  yield all([
    takeLatest(types.SEARCH_CUSTOMER, searchCustomerSaga),
    takeLatest(types.MIGRATE_CUSTOMER, migrateCustomerSaga),
  ])
}
