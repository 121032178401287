import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { t } from 'ab18n'
import { Card, Tabs, Button } from 'antd'

import { actions } from './actions'
import ItemTab from './ItemTab'
import './Vixen.sass'

const { TabPane } = Tabs

const tabs = ['products', 'paymentMethods']

const mapStateToProps = ({ vixen }) => ({ vixen })
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch)

class Vixen extends Component {
  state = { currentTab: 'products' }

  componentDidMount() {
    const { currentTab } = this.state
    this.getTab(currentTab)
  }

  getTab(tab) {
    const { fetchTab } = this.props
    fetchTab(tab)
  }

  changeTab = (key) => {
    const tab = key.replace(/([A-Z])/g, '-$1').toLowerCase()
    this.setState({ currentTab: tab })
    this.getTab(tab)
  }

  handleInputChange = (item, vixenId) => {
    const { currentTab } = this.state
    const { postItem } = this.props

    if (item.vixenId !== vixenId && !!vixenId.length) {
      postItem({
        ...item,
        hasVixexId: item.vixenId,
        vixenId,
        currentTab,
      })
    }
  }

  syncVixen = () => {
    const { currentTab } = this.state
    this.getTab(currentTab)
  }

  render() {
    const { vixen } = this.props
    const SyncVixen = (
      <Button
        disabled
        icon="sync"
        type="primary"
        onClick={this.syncVixen}
      >
        { t('vixen.tabs.sync') }
      </Button>
    )

    return (
      <Card>
        <Tabs
          defaultActiveKey="0"
          onTabClick={this.changeTab}
          tabBarExtraContent={SyncVixen}
        >
          {
            tabs.map(tab => (
              <TabPane
                key={tab}
                tab={t(`vixen.tabs.${tab}`)}
                disabled={vixen.loading}
              >
                <ItemTab
                  data={vixen.data || []}
                  loading={vixen.loading}
                  handleInputChange={this.handleInputChange}
                />
              </TabPane>
            ))
          }
        </Tabs>
      </Card>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vixen)
