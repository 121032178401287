import React, { Component } from 'react'
import { t } from 'ab18n'
import {
  Button,
  List,
  Select,
  Input,
  Modal,
} from 'antd'

import RenderList from 'components/RenderList'

class AddNew extends Component {
  state = {
    showModal: false,
  }

  getProductQuantity = (data) => {
    const { newPurchase } = this.props

    const product = newPurchase.products.filter(item => (
      item.product === data.productId
    ))

    return product.length > 0 ? product[0].quantity : 0
  }

  toggleModal = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal,
    }))
  }

  render() {
    const {
      products,
      newPurchase,
      handleChanges,
      handleSubmit,
      search,
      loading,
      error,
    } = this.props
    const { showModal } = this.state

    return (
      <div className="add-purchase">
        <div className="purchase-row">
          <div className="type">
            <span>{ t('productPurchase.modal.form.type') }</span>
            <Select
              onChange={e => handleChanges('type', e)}
              value={newPurchase.type}
            >
              <Select.Option value="2">
                { t(`productPurchase.modal.form.options.${2}`) }
              </Select.Option>
              <Select.Option value="-2">
                { t(`productPurchase.modal.form.options.${'-2'}`) }
              </Select.Option>
              <Select.Option value="3">
                { t(`productPurchase.modal.form.options.${3}`) }
              </Select.Option>
            </Select>
          </div>
          {
            +newPurchase.type === 3
            && (
              <div className="nf">
                <span>{ t('productPurchase.modal.form.nf') }</span>
                <Input
                  onChange={e => handleChanges('nf', e.target.value)}
                  value={newPurchase.nf || ''}
                />
                {
                  error !== '' && (
                    <div className="validate-errors">
                      { error }
                    </div>
                  )
                }
              </div>
            )
          }
        </div>
        <div className="list-products">
          <div className="title">
            { t('productPurchase.modal.form.products') }
            <Input.Search
              placeholder={t('productPurchase.modal.filter')}
              onChange={e => search(e.target.value)}
            />
          </div>
          <div className="content">
            {
              products && (
                <List
                  size="small"
                  dataSource={products}
                  renderItem={item => (
                    <RenderList
                      {...item}
                      id={item.productId}
                      value={this.getProductQuantity(item)}
                      width={150}
                      type="number"
                      handleInputChange={handleChanges}
                    />
                  )}
                />
              )
            }
          </div>
          <div className="footer">
            <Button
              type="primary"
              loading={loading}
              onClick={() => this.toggleModal()}
            >
              { t('productPurchase.modal.form.submit') }
            </Button>
          </div>
        </div>
        <Modal
          width="30%"
          visible={showModal}
          onCancel={() => this.toggleModal()}
          footer={false}
        >
          <div className="confirm-modal">
            <div className="message">
              { t('productPurchase.modal.confirmation.message') }
            </div>
            <div className="buttons">
              <Button
                type="primary"
                onClick={() => {
                  this.toggleModal()
                  handleSubmit()
                }}
              >
                { t('productPurchase.modal.confirmation.confirm') }
              </Button>
              <Button
                onClick={() => this.toggleModal()}
                type="danger"
              >
                { t('productPurchase.modal.confirmation.cancel') }
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default AddNew
