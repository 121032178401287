import { typeActions } from 'state/util'

const { actions, types } = typeActions('products', {
  fetchProducts: unitId => ({ meta: { unitId } }),
  setAll: (data = null, error = null) => ({
    payload: data,
    error,
  }),
  postItem: data => ({ payload: data }),
  setItem: data => ({ payload: data }),
  setLoading: value => ({ meta: { loading: value } }),
})

export {
  actions,
  types,
}
