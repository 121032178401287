import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { t } from 'ab18n'
import {
  Card,
  Select,
  Modal,
  Button,
} from 'antd'

import { actions as unitActions } from 'pages/units/actions'
import { TableComponent } from 'components'
import FormSearch from './FormSearch'
import { actions } from './actions'
import './Customers.sass'

const columns = () => ([
  {
    title: t('customers.table.identifier'),
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: t('customers.table.name'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: t('customers.table.originUnit'),
    dataIndex: 'unit',
    key: 'unit',
  },
  {
    title: '',
    dataIndex: 'action',
    className: 'nowrap',
    align: 'right',
    width: 300,
  },
])

const mapStateToProps = ({ customer, units }) => ({ customer, units })
const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...actions,
    ...unitActions,
  },
  dispatch,
)

class Customers extends Component {
  state = {
    selectedUnit: null,
    openModal: false,
  }

  componentDidMount() {
    const { fetchUnits } = this.props

    fetchUnits()
  }

  showModal = (id) => {
    this.setState({
      openModal: true,
      selectedUnit: id,
    })
  }

  closeModal = () => {
    this.setState({
      openModal: false,
      selectedUnit: null,
    })
  }

  getUnitEmail = (id) => {
    const { units: { data } } = this.props

    const unit = data.filter(item => item.id === id)

    return unit[0].email
  }

  render() {
    const {
      searchCustomer,
      customer: {
        customer,
        loading,
      },
      units: {
        data,
      },
      migrateCustomer,
    } = this.props
    const { openModal, selectedUnit } = this.state

    const dataSource = customer && [{ ...customer }].map(item => ({
      ...item,
      id: item.identifier,
      unit: item.originalUnit.email,
      action: (
        <Select
          showSearch
          style={{ width: '100%' }}
          placeholder={t('customers.table.select')}
          optionFilterProp="children"
          onChange={e => this.showModal(e)}
          filterOption={(input, option) => (
            option.props.children.toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          )}
        >
          {
            (data || []).map(unit => (
              <Select.Option
                value={unit.id}
                key={unit.id}
                disabled={unit.id === item.originalUnit.id}
              >
                { unit.email }
              </Select.Option>
            ))
          }
        </Select>
      ),
    }))

    return (
      <Card title={t('customers.title')}>
        <FormSearch submit={searchCustomer} />
        <TableComponent
          pagination={false}
          columns={columns()}
          loading={loading}
          rowKey="identifier"
          dataSource={dataSource || []}
        />
        <Modal
          width="50%"
          visible={openModal}
          modalChange={() => this.closeModal()}
          footer={[
            <Button
              type="danger"
              key="back"
              loading={loading}
              onClick={() => migrateCustomer(
                {
                  unit: selectedUnit,
                  customer: customer.identifier,
                },
                {
                  after: this.closeModal,
                },
              )}
            >
              { t('customers.modal.confirm') }
            </Button>,
            <Button key="submit" onClick={() => this.closeModal()}>
              { t('customers.modal.cancel') }
            </Button>,
          ]}
        >
          {
            selectedUnit && (
              <div className="confirm-migration">
                <div
                  className="message"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'customers.modal.confirmation',
                      {
                        customer: customer.name,
                        from: customer.originalUnit.email,
                        to: this.getUnitEmail(selectedUnit),
                      },
                    ),
                  }}
                />
              </div>
            )
          }
        </Modal>
      </Card>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Customers)
